<template>
  <div>
    <b-alert v-model="showAlert" dismissible>
      {{ response }}
    </b-alert>
    <b-container fluid>
      <b-row align-v="center" align-h="center">
        <label for="publisher" class="input-control text-lg mb-0">Publisher:</label>
        <select v-model="selectedPublisher" id="publisher"
          class="form-control bg-light rounded-full text-base w-50 ml-3" @change="getData()">
          <option :value="null">Select Publisher</option>
          <option v-for="publisher in publishers" :value="publisher.id" :key="publisher.id">
            {{ publisher.name }}
          </option>
        </select>
      </b-row>
    </b-container>
    <div v-if="loading" class="my-4 text-center">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else-if="selectedPublisher == null">
      <h5 class="m-4">Please select a publisher to edit its SSPs</h5>
    </div>
    <div v-else>
      <b-container fluid class="mt-4 text-black">
        <b-row class="flex justify-between">
          <b-col cols=0 class="text-base">SSP</b-col>

          <vs-button size="small" color="primary" class="m-2" @click="generatePbConfig()">
            Generate pb_config
          </vs-button>
        </b-row>

        <b-row v-for="({ isActive, ...values }, ssp) in ssps" :key="ssp" class="border-top pt-3 pb-3">
          <b-col cols=0>
            <component :is="dynamicFieldComponent(isActive.type)" :value=isActive :isSSPActive="true" />
          </b-col>
          <b-col cols=1 class="text-lg font-medium">{{ ssp }}</b-col>
          <b-col cols=6>
            <b-container fluid>
              <b-row class="p-1" align-v="center" v-for="(value, key) in values" :key="key">
                <b-col cols="2" class="text-right text-md">
                  <label class="mb-0" :for="key">{{ key }}</label>
                </b-col>
                <b-col cols="4">
                  <component class="h-25 rounded-full text-sm pt-1 pb-1"
                    v-bind:class="{ 'bg-light': value.type !== 'boolean' }" :is="dynamicFieldComponent(value.type)"
                    :value="value" :isSSPActive="true" />
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>

      </b-container>
      <b-container fluid class="mt-3 mb-2">
        <b-row align-h="center">
          <vs-button class="rounded-full" @click="onSubmit">Submit</vs-button>
          <vs-button class="rounded-full ml-6" type="border" @click="onReset">Discard Changes</vs-button>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { PUBLISHERS } from "../../../utils/product_entities";
import { dynamicFieldComponent } from "../../../utils/prebid_config";

export default {
  data() {
    return {
      loading: false,
      ssps: [],
      publishers: PUBLISHERS,
      selectedPublisher: null,
      response: null,
      showAlert: false,
      dynamicFieldComponent: dynamicFieldComponent,
    };
  },
  methods: {
    getData() {
      if (!this.selectedPublisher) return;

      this.loading = true;
      this.$http
        .get(
          `${this.host}/settings/prebid_ssps_index?publisher_id=${this.selectedPublisher}`
        )
        .then(res => {
          const sortObject = obj => Object.keys(obj).sort().reduce((sorted, key) => (sorted[key] = obj[key], sorted), {});
          this.loading = false;
          this.ssps = sortObject(res.data);
        });
    },
    onSubmit() {
      this.$http
        .post(
          `${this.host}/settings/prebid_ssps_update?publisher_id=${this.selectedPublisher}`,
          { ssps: this.ssps }
        )
        .then((res) => (this.response = res.data.result))
        .catch((ex) => (this.response = ex.response.data.error))
        .finally(() => (this.showAlert = true));
    },
    onReset() {
      if (confirm("Are you sure you want to reset? All changes will be lost."))
        this.getData();
    },
    generatePbConfig() {
      this.showAlert = false;
      this.submitError = false;

      const publisherName = this.publishers.find(
        p => p.id === this.selectedPublisher
      ).name;
      if (
        !confirm(
          this.formChanged
            ? `⚠️⚠️⚠️ There are unsaved changes ⚠️⚠️⚠️\nDeploy monetization configuration for ${publisherName} anyway?`
            : `You are about to deploy a new monetization configuration for ${publisherName}, Continue?`
        )
      )
        return

      this.$http
        .post(`${this.host}/settings/prebid_generate_pb_config`, {
          publisher_id: this.selectedPublisher
        })
        .then(res => this.response = res.data.result)
        .catch(ex => { this.response = ex.response.data.submitError; this.submitError = true })
        .finally(() => this.showAlert = true);
    }
  },
};
</script>
